<template>
    <Modal
        :title="$lng.__('lk_webmaster_domains', 'Delete domain')"
        v-if="show"
        @close="close()">
        <div class="pt-20 text-center">

            <Loading v-if="deleteRequestTime"></Loading>

            <template v-if="!deleteRequestTime">
                <p class="pb-30">
                    {{ $lng.__('lk_webmaster_domains', 'Delete domain') }} <strong>{{ domain.url }}</strong>?
                </p>
                <div class="wv-btns wv-btns-center">
                    <button
                        class="wv-btn--red wv-btn--text"
                        @click="deleteDomain">
                        {{ $lng.__('lk_webmaster_domains', 'Delete domain') }}
                    </button>
                    <button
                        class="wv-btn--green wv-btn--text"
                        @click="close()">
                        {{ $lng.__('lk_webmaster_domains', 'Cancel') }}
                    </button>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script>
export default {
    name: 'DomainsDeleteModal',
    props: {
        domain: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            default: false,
            require: true
        }
    },
    emits: [
        'update:show'
    ],
    data() {
        return {
            deleteRequestTime: false,
        };
    },
    computed: {},
    methods: {
        deleteDomain() {
            this.deleteRequestTime = true;

            this.$store.dispatch('webmaster/deleteDomain', this.domain)
                .then(() => {
                    this.deleteRequestTime = false;
                    this.close();
                    this.$emit('deleted');
                });
        },
        close() {
            this.$emit('update:show', false);
        }
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">

</style>