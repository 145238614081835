<template>
    <Modal
        :width="615"
        :title="$lng.__('lk_webmaster_domains', 'Follow these steps to verify your domain')"
        @close="close()">
        <div class="DomainsManualModal">

            <h2>
                <div class="DomainsManualModal__number">1</div>
                {{ $lng.__('lk_webmaster_domains', 'Go to your DNS provider') }}
            </h2>

            <p class="mb-10">{{ $lng.__('lk_webmaster_domains', 'Go to the DNS provider that you use to manage domain and add the following DNS records.') }}</p>
            <p>{{ $lng.__('lk_webmaster_domains', 'Remove old NS records for your domain.') }}</p>

            <div class="card__separator mt-30 ml-0 mr-0"></div>

            <h2>
                <div class="DomainsManualModal__number">2</div>
                {{ $lng.__('lk_webmaster_domains', 'Add NS records for domain') }}
            </h2>

            <div class="DomainsManualModal__tableWrapper">
                <table class="table DomainsManualModal__table">
                    <thead>
                        <tr>
                            <th>{{ $lng.__('lk_webmaster_domains', 'Record type') }}</th>
                            <th>{{ $lng.__('lk_webmaster_domains', 'Value') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>NS</td>
                            <td>{{ nsServers[0] }}</td>
                        </tr>
                        <tr>
                            <td>NS</td>
                            <td>{{ nsServers[1] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>{{ $lng.__('lk_webmaster_domains', 'Guides for common providers:') }}</p>

            <ul>
                <li>
                    <a
                        target="_blank"
                        href="https://godaddy.com/help/edit-an-ns-record-19213?lc=en-US">
                        GoDaddy
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        href="https://www.namecheap.com/support/knowledgebase/article.aspx/434/2237/how-do-i-set-up-host-records-for-a-domain/">
                        NameCheap
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        href="https://customerservice.networksolutions.com/prweb/PRAuth/webkm/help/article/KC-454/networksolutions">
                        Network Solutions
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        href="https://docs.rackspace.com/support/how-to/creating-dns-records-with-cloud-dns/">
                        Rackspace Cloud DNS
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        href="https://www.domain.com/help/article/dns-management-how-to-update-ns-records">
                        Domain.com
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        href="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/migrate-dns-domain-in-use.html">
                        Amazon Route 53
                    </a>
                </li>
            </ul>

            <div class="card__separator mt-30 ml-0 mr-0"></div>

            <h2>
                <div class="DomainsManualModal__number">3</div>
                {{ $lng.__('lk_webmaster_domains', 'Wait for your domain to be verified') }}
            </h2>

            <p>{{ $lng.__('lk_webmaster_domains', 'After making the above DNS changes, it may take up to 30 minutes for the changes to be applied. If DNS changes have not been applied during specified period - contact the manager.') }}</p>

        </div>
    </Modal>

</template>

<script>
export default {
    name: 'DomainsManualModal',
    props: [],
    data() {
        return {};
    },
    computed: {
        nsServers() {
            return WV.domainZone === 'ru'
                ? ['brianna.ns.cloudflare.com', 'miguel.ns.cloudflare.com']
                : ['lia.ns.cloudflare.com', 'michael.ns.cloudflare.com']
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.DomainsManualModal {
    padding: 10px 0 20px;
    h2 {
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    p {
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
    }
    a {
        color: $color-day-light-blue;
    }
    ul {
        margin-top: 5px;
        li {
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: normal;
            line-height: 18px;
            padding-top: 10px;
        }
    }
    &__number {
        border-radius: 5px;
        background: $color-day-line;
        height: 24px;
        width: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: $font-primary;
        font-weight: 700;
        margin-right: 20px;
    }
    &__table {
        border-radius: 10px;
        &Wrapper {
            border: 1px solid $color-day-bg;
            overflow: hidden;
            border-radius: 5px;
            margin: 10px 0 30px;
        }
        thead {
            tr {
                th {
                    text-align: left;
                    height: 40px;
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 40px;
                    background: $color-day-table;
                    border-bottom: 0;
                }
            }
        }
    }
}

</style>
