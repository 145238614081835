<template>
    <div class="Domains">

        <div class="Domains__add">
            <div class="Domains__add__header">
                <div class="Domains__add__header__text">
                    {{ $lng.__('lk_webmaster_domains', `Don't forget to specify the ns records in your domain registrar's panel`) }}
                </div>

                <a
                    @click="isShowManualModal=true"
                    class="Domains__add__header__help"
                    href="#">
                    {{ $lng.__('lk_webmaster_domains', `How to connect my domain`) }}
                </a>
            </div>
            <Card>
                <h2>{{ $lng.__('lk_webmaster_domains', 'Connect domain') }}</h2>

                <div class="Domains__add__form">
                    <FormInput
                        :label="$lng.__('lk_webmaster_domains', `Enter domain`)"
                        placeholder=""
                        v-model="newDomain"
                        :validations="[
                                    { rule: $validations.isStringNotEmpty(newDomain), message: $lng.__('lk_webmaster_domains', `Enter domain`)},
                                    { rule: $validations.isDomainNameValid(newDomain), message: $lng.__('lk_webmaster_domains', `Check domain name`)},
                                    { rule: $validations.isStringLengthSmallThan(newDomain, 255), message: $lng.__('lk_webmaster_domains', `Domain must be less than 255 symbols`) },
                                ]"
                    ></FormInput>

                    <button
                        @click="createDomain()"
                        class="btn wv-btn--text wv-btn--green">
                        {{ $lng.__('lk_webmaster_domains', 'Connect domain') }}
                    </button>
                </div>

            </Card>
        </div>

        <Loading v-if="isLoadingDomainList"></Loading>

        <div
            v-if="!isLoadingDomainList"
            class="Domains__list">
            <Card>
                <div class="Domains__list__table">
                    <div class="table__wrapper">
                        <table class="table table-hoverable">
                            <thead>
                                <tr>
                                    <th>
                                      {{ $lng.__('lk_webmaster_domains', 'URL') }}
                                    </th>
                                    <th>
                                        {{ $lng.__('lk_webmaster_domains', 'Streams') }}
                                    </th>
                                    <th>
                                        {{ $lng.__('lk_webmaster_domains', 'Status') }}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="domains.length === 0">
                                    <td colspan="3">
                                      {{ $lng.__('lk_webmaster_domains', 'No domains available') }}
                                    </td>
                                </tr>
                                <tr v-for="domain in domains">
                                    <td>{{ domain.url }}</td>
                                    <td>{{ domain.streams }}</td>
                                    <td>
                                        <div
                                            v-if="domain.status"
                                            class="Domains__status"
                                            @click="clickOnStatus(domain.status)"
                                            :data-tippy-content="getStatusTooltip(domain.status)"
                                            :class="[
                                            domain.status === 'Connected' ? 'Domains__status-success' : '',
                                            domain.status === 'Parked' ? 'Domains__status-success' : '',
                                            ]
                                        ">
                                            {{ $lng.__('lk_webmaster_domains', domain.status) }}
                                        </div>
                                    </td>
                                    <td class="Domains__list__btns">
                                        <button
                                            @click="reloadDomainsList()"
                                            :data-tippy-content="$lng.__('lk_webmaster_domains', 'Update domain status')"
                                            class="wv-btn--green">
                                            <SvgUse :width="20" :height="20" id="btnRefresh"></SvgUse>
                                        </button>

                                        <button
                                            v-if="domain.streams === 0"
                                            :data-tippy-content="$lng.__('lk_webmaster_domains', 'Delete domain')"
                                            @click="domainToDelete=domain;isShowDeleteDomainModal=true"
                                            class="wv-btn--red">
                                            <SvgUse :width="20" :height="20" id="btnDelete"></SvgUse>
                                        </button>

                                        <button
                                            v-if="domain.streams !== 0"
                                            :data-tippy-content="$lng.__('lk_webmaster_domains', 'Unlink streams before deleting domain')"
                                            class="wv-btn--red disabled">
                                            <SvgUse :width="20" :height="20" id="btnDelete"></SvgUse>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </Card>
        </div>

        <DomainsManualModal
            v-if="isShowManualModal"
            @close="isShowManualModal=false"
        ></DomainsManualModal>

        <DomainsDeleteModal
            v-if="domainToDelete"
            :domain="domainToDelete"
            @deleted="reloadDomainsList()"
            v-model:show="isShowDeleteDomainModal"
        ></DomainsDeleteModal>

    </div>
</template>

<script>
import DomainsManualModal from './DomainsManualModal.vue';
import DomainsDeleteModal from './DomainsDeleteModal.vue';

export default {
    name: 'Domains',
    props: [],
    data() {
        return {
            isShowManualModal: false,
            isLoadingDomainList: true,
            newDomain: '',
            domainToDelete: false,
            isShowDeleteDomainModal: false,
            domainsUpdatedAt: false,
        };
    },
    computed: {
        domains() {
            return this.$store.state.webmaster.domains;
        },
        isDomainValid() {
            return this.$validations.isStringNotEmpty(this.newDomain)
                && this.$validations.isDomainNameValid(this.newDomain)
                && this.$validations.isStringLengthSmallThan(this.newDomain, 255);
        },
    },
    methods: {
        createDomain() {
            WV.Bus.emit('fireValidations');
            if (this.isDomainValid) {
                this.isLoadingDomainList = true;
                this.$store.dispatch('webmaster/createDomain', this.newDomain)
                    .then(() => {
                        this.newDomain = '';
                        WV.toast.success(this.$lng.__('lk_webmaster_domains', 'Domain has been successfully added and is being verified'));
                        this.reloadDomainsList();
                        WV.Bus.emit('disableValidations');
                        setTimeout(() => {
                            WV.Bus.emit('disableValidations');
                        }, 10);
                    })
                    .catch(error => {
                        if (error.response) {
                            WV.toast.error(error.response.data.message);
                        } else {
                            WV.toast.error(error.message);
                        }
                        this.isLoadingDomainList = false;
                    });
            }
        },
        reloadDomainsList() {
            this.isLoadingDomainList = true;
            this.$store.dispatch('webmaster/getDomains')
                .then(() => {
                    this.isLoadingDomainList = false;
                    this.domainsUpdatedAt = Math.floor(Date.now() / 1000);
                    WV.remountTippy();
                });
        },
        reloadDomainsIfNeed() {
            let currentTimeInSeconds = Math.floor(Date.now() / 1000);
            let secondsFromLastUpdate = currentTimeInSeconds - this.domainsUpdatedAt;
            if (secondsFromLastUpdate > 120) {
                this.reloadDomainsList();
            }
            setTimeout(this.reloadDomainsIfNeed, 1000);
        },
        clickOnStatus(status) {
            if (status === 'Not connected' || status === 'Pending') {
                this.isShowManualModal = true;
            }
        },
        getStatusTooltip(status) {
            switch (status) {
                case 'Pending':
                    return this.$lng.__('lk_webmaster_domains', 'Click to view instructions');
                case 'Not connected':
                    return this.$lng.__('lk_webmaster_domains', 'The domain is not connected. Click to view instructions');
                case 'Parked':
                    return this.$lng.__('lk_webmaster_domains', 'Domain ready to use');
                case 'Connected':
                    return this.$lng.__('lk_webmaster_domains', 'Domain is connected to the stream');
            }
        },
    },
    beforeMount() {
        this.reloadDomainsList();
        this.reloadDomainsIfNeed();

    },
    mounted() {
        setTimeout(WV.tippyMount, 2000);
    },
    components: {
        DomainsManualModal,
        DomainsDeleteModal,
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Domains {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &__add {
        margin-bottom: 20px;
        h2 {
            display: block;
            font-size: 20px;
        }
        &__header {
            background: $color-day-line;
            border-radius: 10px;
            padding: 10px 30px 20px;
            margin-bottom: -10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @include break(lg) {
                flex-direction: row;
                align-items: center;
            }
            &__text {
                color: $color-day-dark-blue;
                font-family: $font-secondary;
                font-size: 14px;
            }
            &__help {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                color: $color-day-dark-blue;
                height: 27px;
                border-radius: 16px;
                background: white;
                font-family: $font-primary;
                font-weight: 600;
                font-size: 14px;
                margin-top: 10px;
                @include break(lg) {
                    margin-top: 0;
                }
            }
        }
        &__form {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @include break(md) {
                flex-direction: row;
                align-items: center;
            }
            button {
                margin: 15px 0 0 0;
                width: 100%;
                @include break(md) {
                    width: auto;
                    margin: 0 0 0 20px;
                }
            }
        }
    }
    &__list {
        &__table {
            margin: -30px;
            thead {
                tr {
                    border-bottom: 2px solid $color-day-line;
                    th {
                        text-align: left;
                        &:first-of-type {
                            padding-left: 30px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid $color-day-line;
                    td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-bottom: 0;
                        &:first-of-type {
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
        &__btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__status {
        padding: 6px 8px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        font-size: 14px;
        line-height: 14px;
        font-family: $font-secondary;
        background: $color-gray2;
        color: white;
        &-success {
            background: $color-day-green;
        }
    }
}

</style>